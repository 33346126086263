:root {
  --gray: #a7b2cc;
  --yellow: #fcff63;
  --blue: #6993ff;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  font-size: 125%;
  line-height: 1.5;
}

img {
  max-width: 100%;
}

.container {
  max-width: 720px;
  margin: 0 auto;
  padding: 5rem 2rem;
}

.hero:not([hidden]) {
  background-color: var(--yellow);
  margin-top: 5rem;
  padding: 5rem 0;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-wrap: wrap;
}

.hero .container {
  padding: 0 2rem;
}

.hero h2 {
  font-size: 250%;
  line-height: 1.1;
}

.hero p {
  font-size: 150%;
}

a {
  color: inherit;
  font-weight: bold;
}

pre code {
  display: block;
  padding: 1rem;
  cursor: text;
  user-select: all;
  background-color: #eee;
  border-radius: 0.25rem;
  overflow-x: auto;
}

fieldset {
  padding: 0;
  border: 0;
  margin-top: 1rem;
}

fieldset legend {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.other-label {
  display: block;
}
.other-label span {
  font-weight: 500;
}
.other-label[hidden] {
  display: none;
}

input[type="url"] {
  width: 100%;
  font: inherit;
  border: 0.1rem solid var(--gray);
  border-radius: 0.2rem;
  padding: 0.2rem 0.5rem;
  margin-top: 0.5rem;
}

:root {
  accent-color: var(--blue);
}

footer {
  text-align: center;
  color: var(--gray);
  margin-top: 10rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer img {
  margin-left: 0.5rem;
  height: 1.25rem;
}

@media screen and (min-width: 800px) {
  .screenshot {
    width: 50%;
    float: right;
    margin-left: 2rem;
  }
}

fieldset p {
  font-size: 85%;
  margin-top: 0.5rem;
  color: var(--gray);
}

p code {
  background-color: #eee;
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
  color: #777;
}

code {
  font-size: 80%;
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
}

header p {
  font-weight: bold;
  line-height: 1.1;
}

p.lead {
  font-size: 200%;
}

header {
  text-align: center;
}

header h1 {
  font-size: 100%;
  color: var(--gray);
  margin-top: 0.5rem;
  font-weight: 500;
}

.logo {
  height: 5rem;
  border-radius: 0.5rem;
}

output p {
  font-weight: bold;
  text-align: center;
}

output {
  margin-bottom: 5rem;
  display: block;
}

details summary {
  text-align: center;
  font-size: 80%;
}

.links a:last-of-type {
  color: var(--blue);
}
